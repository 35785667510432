import React from "react";
import ReactDOM from "react-dom";

// import FormContainer from "./containers/FormContainer";
// import SearchContainerWithSuspense from "./containers/SearchContainerWithSuspense";
import {
  MobileMenu,
  SearchContainerWithSuspense,
  SearchContainerWithSuspenseNew,
  NavSearchContainerWithSuspense,
  DoctorSearchContainerWithSuspense,
  EventSearchContainerWithSuspense,
  EventSearchPrint,
  LocationSearchContainerWithSuspense,
  FindAService,
  PressReleaseNew,
  CareGuide
} from "./containers";

const rootElements = [...document.querySelectorAll("[data-react]")];

const notFound = () => <span>Component Not found</span>;

const getJSX = tag =>
  ({
    location_search: LocationSearchContainerWithSuspense,
    doctor_search: DoctorSearchContainerWithSuspense,
    event_search: EventSearchContainerWithSuspense,
    event_search_print: EventSearchPrint,
    global_search: SearchContainerWithSuspenseNew,
    global_search_nav: NavSearchContainerWithSuspense,
    service_search: FindAService,
    press_release_search: PressReleaseNew,
    mobile_menu: MobileMenu,
    care_guide: CareGuide,
  }[tag] || notFound);

const MountComponents = (() => {
  const init = () => {
    rootElements.forEach(root => {
      const tag = root.dataset.react;
      const Dynamic = { root: getJSX(tag) };

      ReactDOM.render(<Dynamic.root {...root.dataset} />, root);
    });
  };
  return { init };
})();

export default MountComponents;
