import clsx from 'clsx';
import React, { useContext } from 'react';

import { isPopulatedString } from '../../../helpers/stringUtils';

import { CareGuideContext } from './CareGuide';

const CareGuideEmergency = () => {
	const { showEmergency, resourcestrings, hasResourceStrings } = useContext(
		CareGuideContext
	);

	// prettier-ignore
	const hasContent = hasResourceStrings && isPopulatedString(resourcestrings?.emergencyNotification);
	// prettier-ignore
	const hasicon = hasResourceStrings && isPopulatedString(resourcestrings?.emergencyIcon);

	if (!(showEmergency && hasContent)) return null;

	return (
		<p role="alert" className="care-guide__emergency">
			{hasicon && (
				<i className={clsx('fa', resourcestrings?.emergencyIcon)}></i>
			)}{' '}
			{resourcestrings?.emergencyNotification}
		</p>
	);
};

export default CareGuideEmergency;
