import React, { useEffect, useState, useMemo, createContext } from 'react';

import CareGuideCards from './CareGuideCards';
import CareGuideSelect from './CareGuideSelect';
import CareGuideEmergency from './CareGuideEmergency';

/**
 * Try JSON parse as JSON failing to parse will cause major issues.
 *
 * @param {JSON} jsonObject
 * @returns {object | any[]}
 */
const tryJsonParse = (jsonObject) => {
	try {
		const parse = JSON.parse(jsonObject);
		return parse;
	} catch (e) {
		return null;
	}
};

/**
 * Get the card that is set as the Emergency based on the red theme.
 *
 * @param {[]} cards
 * @returns {number | undefined}
 */
const getEmergencyCardIndex = (cards) => {
	if (cards === undefined || cards === null) return undefined;

	const emergencyCard = cards.find((card) => card.theme === 'red');
	if (emergencyCard === undefined) return undefined;

	return emergencyCard.columnIndex;
};


/**
 * 
 * Adds the value to the options based on the label.  
 * There is no real "value" so this is a a bit of a hack to ensure ReactSelect doesn't bomb or act oddly. 
 * 
 * @param {JSON} options 
 * @returns {null | []}
 */
const convertOptions = (options) => {
	const optionsArray = tryJsonParse(options);

	if (optionsArray === undefined || optionsArray === null) return null;

	return optionsArray.map((option) => ({
		...option,
		value: option.label,
	}));
};

/**
 * The Context that will be passed to all of the children components. 
 */
export const CareGuideContext = createContext({});

/**
 * The top level Care Guide Component
 * 
 * @param {object} props 
 * @returns React.JSX.Element | null
 */
const CareGuide = (props) => {
	const [selection, setSelection] = useState(null);
	const [showEmergency, setShowEmergency] = useState(false);
	const [hasSelection, setHasSelection] = useState(false);

	if (
		props?.cards === undefined ||
		props?.options === undefined ||
		props?.resourcestrings === undefined
	)
		return null;

	const cards = useMemo(() => tryJsonParse(props?.cards), [props?.cards]);

	// prettier-ignore
	const options = useMemo(() => convertOptions(props?.options), [props?.options]);

	// prettier-ignore
	const resourcestrings = useMemo(() => tryJsonParse(props?.resourcestrings),[props?.resourcestrings]);

	const emergencyIndex = useMemo(() => getEmergencyCardIndex(cards), [cards]);

	const hasResourceStrings = typeof resourcestrings === 'object';

	// Globally update ShowEmergency
	useEffect(() => {
		const updateHasSelection =
			typeof selection === 'object' && selection !== null;

		setHasSelection(updateHasSelection);

		if (!updateHasSelection) {
			setShowEmergency(false);
			return;
		}

		setShowEmergency(() => selection.recommended === emergencyIndex);
	}, [selection, cards]);

	const contextValue = {
		// Getters
		cards,
		options,
		resourcestrings,
		selection,
		emergencyIndex,
		
		// Conditions
		hasSelection,
		hasResourceStrings,
		showEmergency,

		// Setters
		setSelection,
	};

	if (
		contextValue.cards === undefined ||
		contextValue.cards === null ||
		contextValue.cards?.length === 0
	)
		return null;

	return (
		<CareGuideContext.Provider value={contextValue}>
			<CareGuideSelect />
			<CareGuideEmergency />
			<CareGuideCards />
		</CareGuideContext.Provider>
	);
};

export default CareGuide;
