import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { isPopulatedString } from '../../../helpers/stringUtils';

import { CareGuideContext } from './CareGuide';

const CareGuideCards = () => {
	const { cards, hasSelection } = useContext(CareGuideContext);
	const [hasHeight, setHasHeight] = useState(false);
	const ref = useRef();

	// This will ensure that the image in desktop mode doesn't change size when the amount of cards is reduced below 3.
	useEffect(() => {
		if (ref?.current == undefined || ref?.current === null || hasHeight)
			return;

		setTimeout(() => {
			const image = document.querySelector('.care-guide__image');

			if (image === undefined || image === null) return;

			image.setAttribute('style', `min-height: ${image.offsetHeight}px`);
			setHasHeight(true);
		}, 1500);
	}, [ref?.current]);

	if (cards === null || cards === undefined) return null;

	return (
		<div aria-live="true">
			<ul ref={ref} data-has-selection={hasSelection}>
				{cards.map((card, idx) => (
					<Card key={`${card.title}_${idx}`} {...card} />
				))}
			</ul>
		</div>
	);
};

const Card = (props) => {
	const {
		title,
		description,
		icon,
		theme,
		ctaUrl,
		ctaLabel,
		target,
		columnIndex,
	} = props;
	const { selection, hasSelection, showEmergency } = useContext(
		CareGuideContext
	);

	if (title === undefined || title === null) return null;

	// prettier-ignore
	const selectionHasThisCard = hasSelection && selection?.columns.includes(columnIndex);

	// prettier-ignore
	const isRecommended = hasSelection && columnIndex === selection?.recommended;

	const isEmergencyCard = showEmergency && theme === 'red';

	const hasCta = ctaLabel !== '' && ctaUrl !== '';

	if (hasSelection && !selectionHasThisCard) return null;

	return (
		<li
			className="care-guide__card"
			data-selected={selectionHasThisCard}
			data-recommended={isRecommended}
			data-theme={theme}
			data-emergency={isEmergencyCard}
		>
			<RecommendedOption isRecommended={isRecommended} />
			<div className="care-guide__card-content">
				<span className="care-guide__card-icon">
					<i className={clsx('fa', icon)}></i>
				</span>
				<h3>{title}</h3>
				{description && <p>{description}</p>}

				{hasCta && (
					<a
						className="c-button c-button__blue"
						href={ctaUrl}
						target={target}
					>
						{ctaLabel}
					</a>
				)}
			</div>
		</li>
	);
};

const RecommendedOption = ({ isRecommended }) => {
	const { resourcestrings, hasResourceStrings } = useContext(
		CareGuideContext
	);

	// prettier-ignore
	const hasIcon = hasResourceStrings && isPopulatedString(resourcestrings?.recommendedIcon);

	// prettier-ignore
	const hasLabel = hasResourceStrings && isPopulatedString(resourcestrings?.recommendedLabel);

	if (!(isRecommended && hasLabel)) return null;

	return (
		<div className="care-guide__card-recommended">
			{hasIcon && (
				<i className={clsx('fa', resourcestrings.recommendedIcon)}></i>
			)}

			<strong>{resourcestrings.recommendedLabel}</strong>
		</div>
	);
};

export default CareGuideCards;
