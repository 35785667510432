const colors = {
  white: "#ffffff",
  black: "#222324",
  blue: "#0076A8",
  blueActive: "#005072",
  blueMidLight: "#c7eafb",
  blueLight: "#E5F5FA",
  blueLightActive: "#F1FCFF",
  blueDark: "#02628a",
  blueLightest: "#f2fafc",
  purple: "#890C58",
  purpleActive: "#5E043A",
  green: "#6CA425",
  greenDark: "#56831E",
  greenLight: "#C0DEA8",
  grey: "#f7f7f7",
  greyLight: '#f8f8fb',
  slate: "#506d85",
  greyDark: "#dbdbdb",
  greyDarkest: "#d6d6d6",
  greyMid: "#e8e8e8",
  red: "#D64205",
  redActive: "#a43304",
  redLight: "#fae9ea",
  redEmergency: "#bf1e27",
  pink: "#ffe0ed",
  pinkActive: "#ffc8df",
  palePink: "#FFE5CC",
  darkPink: "#ca0055",
  emerald: "#518014",
  emeraldActive: "#35540d",
  greenButton: "#538316",
  greenButtonActive: "#466F12",
  indigo: "#16167C",
  yellow: "#CA8100",
  yellowActive: "#976413",
  green2025: '#538316',
  yellow2025: '#F5B700',
};

module.exports = {
  fontSize: {
    regular: "1.6rem",
    small: "1.4rem"
  },
  color: {
    black: colors.black,
    white: colors.white,
    //uiPrimary: colors.purple,
    //uiPrimaryActive: colors.purpleActive,
    //uiSecondary: colors.blue,
    //uiSecondaryActive: colors.blueActive,
    uiPrimary: colors.blue,
    uiPrimaryActive: colors.blueActive,
    //uiPrimary: colors.green,
    //uiPrimaryActive: colors.purpleActive,
    uiSecondary: colors.blue,
    uiSecondaryActive: colors.blueActive,

    uiTertiary: colors.green,
    uiTertiaryDark: colors.greenDark,
    uiQuaternary: colors.slate,
    uiEmerald: colors.emerald,
    uiEmeraldActive: colors.emeraldActive,
    uiGreenButton: colors.greenButton,
    uiGreenButtonActive: colors.greenButtonActive,
    uiRed: colors.red,
    uiRedActive: colors.redActive,
    uiPink: colors.pink,
    uiPinkActive: colors.pinkActive,
    uiDarkPink: colors.darkPink,
    textColor: colors.black,
    textColorSecondary: colors.slate,
    backgroundMain: colors.white,
    backgroundSubNav: colors.blueLight,
    grey: colors.grey,
    greyDark: colors.greyDark,
    greyDarkest: colors.greyDarkest,
    greyMid: colors.greyMid,
    error: colors.red,
    alert: colors.red,
    indigo: colors.indigo,
    uiYellow: colors.yellow,
    uiYellowActive: colors.yellowActive,
    blueLight: colors.blueLight,
    blueLightActive: colors.blueLightActive,
    blueDark: colors.blueDark,
    greenLight: colors.greenLight,
    blueLightest: colors.blueLightest,
    redLight: colors.redLight,
    redEmergency: colors.redEmergency,
    blueMidLight: colors.blueMidLight,
    greyLight: colors.greyLight,
    slate: colors.slate,
    palePink: colors.palePink,
    green2025: colors.green2025,
    yellow2025: colors.yellow2025
  },
  overlay: {
    green: "rgba(74,105,27,.9)",
    blue: "rgba(0,100,143,.9)"
  },
  // boxShadow: "0 6px 15px 0 rgba(0,0,0,.1)",
  boxShadow: "0 8px 10px -6px rgba(0,0,0,.09)",
  fonts: {
    fontPrimary: '"Gotham Book", sans-serif',
    fontPrimary: '"Gotham Book",sans-serif',
    fontSecondary: '"Gotham Medium", sans-serif',
    fontLink: '"Gotham Bold", sans-serif',
    fontBold: '"Gotham Bold", sans-serif',
  },
  padding: {
    small: ".5rem",
    forms: "2.5rem 3rem 1.1rem"
  },
  border: "1px solid #e2e2e2",
  margin: {
    nestedControl: "2rem 1.7rem 1rem",
    label: "0 0 1.1rem 0"
  },
  select: {
    fontWeight: "700",
    fontSize: "1.6rem",
    backgroundImage: `url("data:image/svg+xml,%0A%3Csvg fill='%2303539D' width='292.36px' height='292.36px' enable-background='new 0 0 292.362 292.362' version='1.1' viewBox='0 0 292.362 292.362' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m286.94 69.377c-3.614-3.617-7.898-5.424-12.848-5.424h-255.81c-4.952 0-9.233 1.807-12.85 5.424-3.617 3.621-5.424 7.902-5.424 12.851 0 4.948 1.807 9.229 5.424 12.847l127.91 127.91c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428l127.91-127.91c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z'/%3E%3C/svg%3E "),linear-gradient(90deg,#e2e2e2,#e2e2e2 1px,#fff 0,#fff 0)`,
    hover: {
      backgroundImage: `url("data:image/svg+xml,%0A%3Csvg fill='%23ffffff' width='292.36px' height='292.36px' enable-background='new 0 0 292.362 292.362' version='1.1' viewBox='0 0 292.362 292.362' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m286.94 69.377c-3.614-3.617-7.898-5.424-12.848-5.424h-255.81c-4.952 0-9.233 1.807-12.85 5.424-3.617 3.621-5.424 7.902-5.424 12.851 0 4.948 1.807 9.229 5.424 12.847l127.91 127.91c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428l127.91-127.91c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z'/%3E%3C/svg%3E "), linear-gradient(90deg,#03539D,#03539D 1px,#03539D 0,#03539D 0)`
    },
    focus: {
      backgroundImage: `url("data:image/svg+xml,%0A%3Csvg fill='%23ffffff' width='292.36px' height='292.36px' enable-background='new 0 0 292.362 292.362' version='1.1' viewBox='0 0 292.362 292.362' xml:space='preserve' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m286.94 69.377c-3.614-3.617-7.898-5.424-12.848-5.424h-255.81c-4.952 0-9.233 1.807-12.85 5.424-3.617 3.621-5.424 7.902-5.424 12.851 0 4.948 1.807 9.229 5.424 12.847l127.91 127.91c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428l127.91-127.91c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z'/%3E%3C/svg%3E "), linear-gradient(90deg, #01203d, #01203d 1px, #01203d 0,#01203d 0)`
    }
  },
  breakpoint: {
    max: "@media (min-width: 76em)",
    desktop: "@media (min-width: 70em)",
    laptop: "@media (min-width: 64em)",
    tablet: "@media (min-width: 50em)",
    phablet: "@media (min-width: 37.5em)",
    mobileonly: "@media (max-width: 37.5em)"
  }
};