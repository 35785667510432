import clsx from 'clsx';
import Select from 'react-select';
import React, { useContext, useState } from 'react';

import theme from '../../../../theme';
import { isPopulatedString } from '../../../helpers/stringUtils';

import { CareGuideContext } from './CareGuide';

const color = theme.color;
const indicators = (styles) => ({
	...styles,
	color: color.white,
	':hover': {
		...styles[':hover'],
		color: color.white,
		opacity: 0.7,
	},
	':focus': {
		...styles[':focus'],
		color: color.white,
		opacity: 0.7,
		outline: `1px solid ${color.white}`,
		outlineOffset: '2px',
	},
});

/**
 * Outputs a React Select component
 *
 * @link https://react-select.com/
 * @returns ReactSelect
 */
const CareGuideSelect = () => {
	const {
		options,
		setSelection,
		resourcestrings,
		showEmergency,
	} = useContext(CareGuideContext);

	const [placeholder, setPlaceholder] = useState(
		isPopulatedString(resourcestrings?.dropdownLabel)
			? resourcestrings?.dropdownLabel
			: 'What is your primary symptom?'
	);

	/**
	 * Setup the colors fot the React Select
	 * @link https://react-select.com/styles
	 */
	const colorStyles = {
		control: (styles, { isFocused }) => ({
			...styles,
			backgroundColor: color.blueDark,
			color: color.white,
			minHeight: '50px',
			fontSize: '14px',
			borderColor: color.blueDark,
			outline: isFocused ? `2px solid ${color.blueDark}` : undefined,
			outlineOffset: isFocused ? '4px' : undefined,
			':hover': {
				...styles[':hover'],
				borderColor: color.blueDark,
			},
			':focus': {
				...styles[':focus'],
				outline: `2px solid ${color.blueDark}`,
				outlineOffset: '4px',
			},
		}),
		option: (styles, { isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor:
					isSelected || isFocused || isDisabled
						? color.blueDark
						: '#F0F0F0',
				color:
					isSelected || isFocused || isDisabled
						? color.white
						: color.black,
				cursor: isDisabled ? 'not-allowed' : 'pointer',
				fontSize: '14px',
				':active': {
					...styles[':active'],
					background: color.blueDark,
					color: color.white,
				},
			};
		},
		menu: (styles) => ({
			...styles,
			backgroundColor: '#F0F0F0',
			marginTop: '0px',
		}),
		menuPortal: (styles) => ({
			...styles,
			backgroundColor: '#F0F0F0',
			marginTop: '0px',
		}),
		singleValue: (styles) => ({
			...styles,
			color: color.white,
			fontSize: '14px',
		}),
		input: (styles) => ({
			...styles,
			color: color.white,
			fontSize: '14px',
		}),
		placeholder: (styles) => ({ ...styles, color: color.white }),
		clearIndicator: indicators,
		dropdownIndicator: indicators,
		indicatorSeparator: (styles) => ({
			...styles,
			color: color.white,
			opacity: 0.7,
		}),
		noOptionsMessage: (styles) => ({
			...styles,
			color: color.black,
			textAlign: 'left',
			fontSize: '14px',
		}),
	};
	const id = 'careguide_select';

	if (options === undefined || options === null) return null;

	return (
		<>
			<label id={id} className='sr-only'>{resourcestrings?.dropdownLabel}</label>
			<Select
				isClearable
				options={options}
				clearValue={null}
				defaultValue={null}
				isSearchable={true}
				aria-labelledby={id}
				styles={colorStyles}
				placeholder={placeholder}
				aria-label={() => resourcestrings?.dropdownLabel}
				className={clsx(
					'care-guide__select',
					showEmergency && 'care-guide__select-emergency'
				)}
				escapeClearsValue={true}
				noOptionsMessage={() =>
					isPopulatedString(resourcestrings?.dropdownNoOptions)
						? resourcestrings.dropdownNoOptions
						: 'No symptoms found.'
				}
				onMenuOpen={() =>
					setPlaceholder(resourcestrings?.dropdownPlaceholder)
				}
				onMenuClose={() =>
					setPlaceholder(resourcestrings?.dropdownLabel)
				}
				onChange={(selectedOption, triggeredAction) => {
					if (triggeredAction.action === 'clear') {
						setSelection(null);
						return;
					}
					if (selectedOption) {
						setSelection(selectedOption);
					}
				}}
			/>
		</>
	);
};

export default CareGuideSelect;
